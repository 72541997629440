import { AppManifest } from '@wix/platform-editor-sdk';
import { TranslationFunction } from 'i18next';

import { applicationActions } from '../actions';

type ApplicationActions = NonNullable<AppManifest['pages']>['applicationActions'];

export const getApplicationActions = (t: TranslationFunction, showGeneralSettings: boolean): ApplicationActions => {
  const actions = [
    applicationActions.getAddPageAction(t),
    applicationActions.viewSiteMembersAction,
    ...(showGeneralSettings ? [applicationActions.getOpenMemberPrivacyDashboardAction(t)] : []),
    applicationActions.openSignUpSettingsAction,
    applicationActions.getDeleteMembersAreaAction(t),
  ];

  return { default: actions };
};
